'use client';

import React from 'react';

import { useParams, usePathname } from 'next/navigation';

import i18n from 'config/i18n';

import { TNextClientComponent } from 'types/next';

import Icon from 'components/custom/Icon';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from 'components/tailwind-catalyst/Dropdown';

import { styles } from '.';

type TProps = {
  lang?: string;
};

const LangPicker: TNextClientComponent<TProps> = () => {
  const params = useParams();
  const pathname = usePathname();

  const languages = i18n.locales.map((location) => ({
    id: location,
    label: location,
    href: pathname?.replace(params?.lang as string, location),
  }));

  return (
    <Dropdown>
      <DropdownButton outline className={styles.button}>
        {params?.lang}
        <Icon accessor="chevron-down" className={styles.icon} size="8px" />
      </DropdownButton>
      <DropdownMenu>
        {languages.map((item) => (
          <DropdownItem key={item.id} href={item.href} className={styles.item}>
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LangPicker;
